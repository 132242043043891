<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title fs25">Menu Items</div>
          <button
            v-if="isAuthorized(permissions.menu_item_create)"
            class="btn btn-sm btn-primary p-2 btn-resize-standard"
            @click="handleActionToggle"
          >
            <i class="icon-add"/>Add New
          </button>
        </div>
        <div class="card-body" v-loading="state.loading">

          <!--list-->
          <ItemTable
            :data="state.items"
            :columns="state.columnMap"
            :show-actions="isAuthorized(permissionsGroup.items)"
          >
            <template #datetime="{item}">
              <span>{{ dateFormat(item.datetime) }}</span>
            </template>

            <template #category="{item}">
              <span>{{ item.category.name }}</span>
            </template>

            <template #group="{item}">
              <span v-html="renderGroup(item)"/>
            </template>

            <template #price="{item}">
              <span>{{ item.price.toFixed(2) }}</span>
            </template>

            <template #status="{item}">
              <span
                v-html="renderBadge(item.status)"
              ></span>
            </template>

            <template #action="{item}" v-if="isAuthorized(permissionsGroup.items)">
              <EditButton
                class="mb-1"
                v-if="isAuthorized(permissions.menu_item_update)"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_UPDATE)"
              />

              <DeleteButton
                v-if="isAuthorized(permissions.menu_item_delete)"
                :loading="item.id === state.action.subject?.id && state.action.deleteLoading"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_DELETE)"
              />

            </template>

          </ItemTable>

          <!--Add Modal-->
          <ItemAction
            :show="state.showAction"
            :item="state.action.subject"
            :updating="state.action.types.edit"
            @hide="handleActionToggle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue';
import ItemTable from '@/components/Util/ItemTable';
import { dateFormat } from '@/Mixins/appHelper';
import ButtonComp from '@/components/Util/ButtonComp';
import { useStore } from 'vuex';
import ItemAction from '@/components/Menu/ItemAction';
import { constants } from '@/utils';
import Toaster from '@/utils/Toaster';
import permissions, { permissionsGroup } from '@/utils/permissions';
import { fetchDataIfNotExist, renderEnabledBadge } from '@/utils/Helper';
import EditButton from '@/components/Util/ListActions/EditButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';

export default {
  name: 'Items',
  components: { DeleteButton, EditButton, ItemTable, ButtonComp, ItemAction },
  setup () {

    const store = useStore();
    const itemStore = store.state.menu.items;

    const state = reactive({
      showAction: false,

      loading: false,

      items: computed(() => itemStore.data.items || []),
      columnMap: [
        { field: 'name', header: 'Name' },
        { field: 'category', header: 'Category', sortable: true },
        { field: 'group', header: 'Group', sortable: true },
        { field: 'price', header: 'Price', sortable: true },
        { field: 'sort', header: 'Sort', sortable: true },
        { field: 'status', header: 'Status', sortable: true },
        //{ field: 'action', header: 'Action', sortable: false },
      ],

      action: {
        types: {
          status: false,
          edit: false,
          delete: false
        },
        subject: false,
        deleteLoading: false
      },
    });

    const getData = async () => {

      // :TODO Reduce API Call
      //const currentData = computed(() => itemStore?.data?.items || []);
      //
      //if (currentData.value.length) return;

      state.loading = true;
      await store.dispatch('menu/fetchCategories');
      await store.dispatch('menu/fetchItems');
      await store.dispatch('menu/fetchAddons');

      state.loading = false;
    };

    onMounted(() => {
      fetchDataIfNotExist(itemStore.data, async () => {
        await getData();
      });
    });

    const renderBadge = (data) => {
      return renderEnabledBadge(data);
    };

    const renderGroup = (item) => {

      if (item.is_group) {
        return '<span class="badge table-badge badge-primary badge-pill">Main Group</span>';
      }

      if (item.group_id) {
        return item?.group?.name;
      }

      return 'N/A';
    };

    const handleActionToggle = (value) => {

      // set the desired action or just toggle
      const newValue = !!value || !state.showAction;

      state.showAction = newValue;

      // if closing the action then make edit false
      if (!newValue) {
        state.action.types.edit = false;
        state.action.subject = false;
      }

    };

    const handleDelete = (item) => {

      SweetAlert.confirmError({
        text: `This will permanently Delete '${item.name}'`,
        preConfirm: async (status) => {

          if (!status) return;

          state.action.deleteLoading = true;

          try {

            await store.dispatch('menu/deleteItem', item.id);

            Toaster.successAlt({
              title: 'Deleted',
              message: `'${item.name}' has been deleted`
            });

          } catch (e) {
            Toaster.error({
              title: 'Deletion Failed',
              message: e.message
            });
          }

          state.action.deleteLoading = false;

        }
      });

    };

    const handleAction = (item, actionType) => {

      state.action.subject = item;

      switch (actionType) {
        case constants.LIST_ITEM_ACTION_UPDATE:
          state.action.types.edit = true;
          handleActionToggle();
          break;

        case constants.LIST_ITEM_ACTION_DELETE:
          state.action.types.delete = true;
          handleDelete(item);
          break;
      }

    };

    return {
      state,
      constants,
      handleAction,
      dateFormat,
      renderBadge,
      renderGroup,
      handleActionToggle,
      permissionsGroup,
      permissions
    };
  }
};
</script>

<style>
.table-badge {
  font-size: 12px;
}
.cursor-initial{
  cursor: initial;
}
</style>
